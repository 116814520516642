






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import * as types from '@/store/account/types';
import VButton from '@/components/VButton.vue';
import SmallCard from '@/components/cards/SmallCard.vue';
import { apiUrl } from '@/store/adapter';

@Component({
  components: { VButton, SmallCard },
})
export default class Login extends Vue {

  @Prop({ default: false }) private microsoftLogin!: boolean;

  @Action('login', { namespace: 'account' }) private login!: types.LoginAction;

  private loggingIn: boolean = false;
  private username: string = '';
  private password: string = '';
  private passwordValid: boolean | null = null;
  private usernameValid: boolean | null = null;
  private serverError = '';

  private mounted() {
    if (this.microsoftLogin) {
      window.location = `
        ${apiUrl}/microsoft-auth/login?originUrl=${window.location.origin}
      ` as (string | Location) & Location;
    } else {
      (this.$refs.login as HTMLElement).focus();
    }
  }
  private submit() {
    this.loggingIn = true;
    this.login({ username: this.username, password: this.password }).then(() => {
      this.$router.push({ name: 'tool' });
    }).catch((error: any) => {
      if (error.status !== 400 && error.response && error.response.status !== 400 && (
        (error.response as any).data as any).non_field_errors.includes('E-mail is not verified.')
      ) {
        this.serverError = 'E-mail adres is nog niet geactiveerd';
      } else {
        this.serverError = 'Ongeldige gebruikersnaam/wachtwoord';
      }
      this.password = '';
      this.passwordValid = false;
      this.usernameValid = false;
    }).finally(() => {
      this.loggingIn = false;
    });
  }
}
